import { useContext, useState, useRef, useEffect } from 'react';
import 'devextreme-react/text-area';
import './apiKeys.scss';
import 'devextreme/data/odata/store';
import { apiBaseUrl } from '../../config/globalVariables';
import DataGrid, {
	Column,
	Grouping,
	GroupPanel,
	Pager,
	Paging,
	FilterRow,
	FilterPanel,
	HeaderFilter,
	Export,
	Scrolling,
	RemoteOperations,
	ColumnFixing,
	ColumnChooser,
	DataGridTypes,
} from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '../../utils/showNotification';
import { useAuth } from '../../contexts/auth';
import { UiConfigContext } from '../../contexts/uiConfig';
import { getUserPermissions } from '../../config/actions';
import { getRealmHeaders, getUserHeaders } from '../../api/config';
import ActionCell from './apiKeysActionsCellRender';
import { statusLookup } from '../../api/status';

export const DataGridApiKeys = () => {
	const navigate = useNavigate();
	const { user } = useAuth();
	const userPermissions = getUserPermissions(user);
	const { signOut } = useAuth();
	const { uiConfig } = useContext(UiConfigContext);
	const [filterVisible, setFilterVisible] = useState(
		uiConfig.dataGrid.defaultFilterVisibility
	);
	const onToolbarPreparing = (e: any) => {
		e.toolbarOptions.items.unshift(
			{
				location: 'before',
				widget: 'dxTextBox',
				options: {
					mode: 'search',
					placeholder: 'Buscar',
					onEnterKey: (args: any) => {
						e.component.searchByText(
							args.component.option('value')
						);
					},
					elementAttr: {
						class: 'finnekta-textbox-filter',
					},
				},
			},
			{
				location: 'before',
				widget: 'dxButton',
				options: {
					icon: 'filter',
					text: filterVisible ? 'Ocultar filtros' : 'Mostrar filtros',
					onClick: () => {
						setFilterVisible(!filterVisible);
					},
					elementAttr: {
						class: 'finnkekta-button-filter',
					},
				},
			}
		);
	};
	const apiKeysDataSource = {
		store: {
			version: 4,
			type: 'odata',
			key: 'id',
			url: `${apiBaseUrl}apikeys`,
			beforeSend: function (request: any) {
				request.headers = {
					...getRealmHeaders(),
					...getUserHeaders(),
				};
			},
			errorHandler: function (error: any) {
				console.log(error.httpStatus);
				if (error.httpStatus === 401) {
					showNotification({
						message:
							'Su sesión ha expirado. Por favor, vuelva a iniciar sesión',
						type: 'error',
						displayTime: 12000,
						position: uiConfig.notificationsPosition,
					});
					signOut();
				}
				if (error.httpStatus === 403) {
					navigate('/forbidden');
				}
			},
		},
		select: [
			'id',
			'name',
			'created',
			'status',
			'company',
			'additionalInfo.permissions',
		],
	};

	const pageSizes = useRef([5, 7, 10, 25, 50, 100]);
	const [updatedPageSizes, setUpdatedPageSizes] = useState(pageSizes.current);

	useEffect(() => {
		const defaultRowsPerPage = uiConfig.dataGrid.defaultRowsPerPage;
		if (!pageSizes.current.includes(defaultRowsPerPage)) {
			const newPageSizes = [
				...pageSizes.current,
				defaultRowsPerPage,
			].sort((a, b) => a - b);
			pageSizes.current = newPageSizes;
			setUpdatedPageSizes(newPageSizes);
		}
	}, [uiConfig]);

	const onExporting = (e: DataGridTypes.ExportingEvent) => {
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet('Main sheet');

		exportDataGrid({
			component: e.component,
			worksheet,
			autoFilterEnabled: true,
		}).then(() => {
			workbook.xlsx.writeBuffer().then((buffer) => {
				saveAs(
					new Blob([buffer], { type: 'application/octet-stream' }),
					'DataGrid.xlsx'
				);
			});
		});
	};

	const renderActionCell = (e: DataGridTypes.CellPreparedEvent) => (
		<ActionCell data={e.data} userPermissions={userPermissions} />
	);

	return (
		<DataGrid
			id="gridContainer"
			dataSource={apiKeysDataSource as any}
			showBorders={false}
			focusedRowEnabled={false}
			columnAutoWidth={true}
			columnHidingEnabled={true}
			hoverStateEnabled={true}
			rowAlternationEnabled={true}
			allowColumnReordering={true}
			allowColumnResizing={true}
			width="100%"
			className="dx-datagrid-filter-top"
			onExporting={onExporting}
			onToolbarPreparing={onToolbarPreparing}
			columnResizingMode="widget"
		>
			<Scrolling mode="standard" />
			<GroupPanel visible={false} />
			<FilterRow visible={filterVisible} />
			<FilterPanel visible={false} />
			<HeaderFilter visible={false} />
			<ColumnFixing enabled={false} />
			<ColumnChooser enabled={false} />
			<Grouping autoExpandAll={true} />
			<RemoteOperations
				filtering={true}
				paging={true}
				sorting={true}
				summary={false}
				grouping={false}
				groupPaging={false}
			/>
			<Column hidingPriority={6} dataField={'name'} caption={'Nombre'} />
			<Column
				width={160}
				hidingPriority={2}
				dataField={'created'}
				caption={'Creado'}
				format="dd/MM/yyyy"
			/>
			<Column
				hidingPriority={5}
				dataField={'status'}
				caption={'Estado'}
				alignment="center"
				lookup={{
					dataSource: statusLookup,
					valueExpr: 'value',
					displayExpr: 'text',
				}}
			/>
			<Column
				caption={'Acciones'}
				width={250}
				cellRender={renderActionCell}
				allowEditing={false}
				alignment="center"
			/>
			<Pager
				allowedPageSizes={updatedPageSizes}
				showPageSizeSelector={true}
				showNavigationButtons={true}
				showInfo={true}
				displayMode="compact"
			/>
			<Paging defaultPageSize={uiConfig.dataGrid.defaultRowsPerPage} />
			<Export enabled={false} />
		</DataGrid>
	);
};
