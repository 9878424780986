import { Button, SelectBox, TagBox, TextBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import './users.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
import { editUser } from '../../api/users';
import { defaultShowTimeSToast } from '../../config/globalVariables';
import { ClickEvent } from 'devextreme/ui/button';
import {
	initialErrors,
	getInitialFormData,
	validate,
	handleClearForm,
	documentsTypesDataSource,
	rolesDataSource,
	handleInputChange,
} from './userFormCommons';

export default function EditUserForm() {
	const location = useLocation();
	const navigate = useNavigate();
	const { userData } = location.state;
	const [submitted, setSubmitted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formData, setFormData] = useState(getInitialFormData(userData));
	const [errors, setErrors] = useState(initialErrors);
	const handleSubmit = async (e: React.FormEvent) => {
		setSubmitted(true);
		const validationErrors = validate(formData);
		if (Object.keys(validationErrors).length === 0) {
			setIsSubmitting(true);
			const payload = {
				fullName: formData.fullName,
				userValue: formData.userValue,
				documentType: formData.documentType,
				documentNumber: formData.documentNumber,
				email: formData.email,
				phone: formData.phone,
				roles: formData.roles,
				isActive: true,
				status: 'ACTIVE',
			};
			const result = await editUser(payload);
			if (result.success) {
				notify(result.message, 'success', defaultShowTimeSToast);
				setTimeout(() => {
					navigate('/users');
				}, 1500);
			} else {
				notify(result.message, 'error', defaultShowTimeSToast);
				setIsSubmitting(false);
			}
		}
	};

	const handleButtonClick = (e: ClickEvent) => {
		e.event!.preventDefault();
		handleSubmit(e as unknown as React.FormEvent);
	};

	useEffect(() => {
		if (submitted) {
			const newErrors = validate(formData);
			setErrors(newErrors);
		}
	}, [submitted, formData]);

	return (
		<React.Fragment>
			<h2 className={'content-block breadcrumb'}>
				<Link to={'/users'}>Usuarios</Link>
				<span className="mdi mdi-greater-than"></span>
				<span className="actual">Editar usuario</span>
			</h2>
			<div className={'content-block'}>
				<div
					className={
						'dx-card responsive-paddings animate__animated animate__fadeIn'
					}
				>
					<div className="finnetka-card-text-header">
						Configuración básica
					</div>
					<form
						className="custom-container-form"
						onSubmit={handleSubmit}
					>
						<TextBox
							value={formData.fullName}
							onValueChanged={(e) =>
								handleInputChange(setFormData, setErrors)(
									'fullName',
									e.value
								)
							}
							label="Nombre Completo"
							isValid={!errors.fullName}
						/>
						<SelectBox
							label="Tipo de documento"
							dataSource={documentsTypesDataSource}
							value={formData.documentType}
							valueExpr="id"
							displayExpr="description"
							onValueChanged={(e) => {
								handleInputChange(setFormData, setErrors)(
									'documentType',
									e.value
								);
								console.log('e.value', e.value);
							}}
							isValid={!errors.documentType}
						/>
						<TextBox
							value={formData.documentNumber}
							onValueChanged={(e) =>
								handleInputChange(setFormData, setErrors)(
									'documentNumber',
									e.value
								)
							}
							label="Número de documento"
							isValid={!errors.documentNumber}
						/>
						<TextBox
							value={formData.email}
							onValueChanged={(e) =>
								handleInputChange(setFormData, setErrors)(
									'email',
									e.value
								)
							}
							label="Correo electrónico"
							mode="email"
							isValid={!errors.email}
						/>
						<TextBox
							value={formData.phone}
							onValueChanged={(e) =>
								handleInputChange(setFormData, setErrors)(
									'phone',
									e.value
								)
							}
							label="Número de celular"
							mode="tel"
							isValid={!errors.phone}
						/>
						<TagBox
							label="Asignar Roles"
							dataSource={rolesDataSource as any}
							valueExpr="id"
							displayExpr="descripcion"
							value={formData.roles}
							onValueChanged={(e) =>
								handleInputChange(setFormData, setErrors)(
									'roles',
									e.value
								)
							}
							isValid={!errors.roles}
						/>
						<br />
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
								gap: '0.5rem',
							}}
						>
							<Button
								text="LIMPIAR FORMULARIO"
								className="finnekta-button-secondary-action"
								onClick={handleClearForm(setFormData)}
							/>
							<Button
								className="finnekta-button-primary-action "
								text="GUARDAR CAMBIOS"
								onClick={handleButtonClick}
								disabled={isSubmitting}
							/>
						</div>
					</form>
				</div>
			</div>
		</React.Fragment>
	);
}
