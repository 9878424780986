import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
	Item,
	Label,
	ButtonItem,
	ButtonOptions,
	RequiredRule,
	CustomRule,
	EmailRule,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { createAccount } from '../../api/auth';
import { ValidationCallbackData } from 'devextreme-react/common';
import './CreateAccountForm.scss';
import { defaultShowTimeSToast } from '../../config/globalVariables';

export default function CreateAccountForm() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const formData = useRef({ email: '', password: '' });

	const onSubmit = useCallback(
		async (e: any) => {
			e.preventDefault();
			const { email, password } = formData.current;
			setLoading(true);

			const result = await createAccount(email, password);
			setLoading(false);

			if (result.isOk) {
				navigate('/login');
			} else {
				notify(result.message, 'error', defaultShowTimeSToast);
			}
		},
		[navigate]
	);

	const confirmPassword = useCallback(
		({ value }: ValidationCallbackData) =>
			value === formData.current.password,
		[]
	);

	// efecto para sobreescribir el background del login con el general
	useEffect(() => {
		// Cuando el componente se monta, agrega la clase
		document.getElementById('app')?.classList.add('app-login');

		// Cuando el componente se desmonta, remueve la clase
		return () => {
			document.getElementById('app')?.classList.remove('app-login');
		};
	}, []);

	return (
		<form className={'create-account-form'} onSubmit={onSubmit}>
			<Form formData={formData.current} disabled={loading}>
				<Item
					dataField={'email'}
					editorType={'dxTextBox'}
					editorOptions={emailEditorOptions}
				>
					<RequiredRule message="Email requerido" />
					<EmailRule message="Email is invalid" />
					<Label visible={false} />
				</Item>
				<Item
					dataField={'password'}
					editorType={'dxTextBox'}
					editorOptions={passwordEditorOptions}
				>
					<RequiredRule message="Contraseña requerida" />
					<Label visible={false} />
				</Item>
				<Item
					dataField={'confirmedPassword'}
					editorType={'dxTextBox'}
					editorOptions={confirmedPasswordEditorOptions}
				>
					<RequiredRule message="Contraseña requerida" />
					<CustomRule
						message={'Las contraseñas no coinciden'}
						validationCallback={confirmPassword}
					/>
					<Label visible={false} />
				</Item>
				<Item>
					<div className="policy-info">
						By creating an account, you agree to the{' '}
						<Link to="#">Terms of Service</Link> and{' '}
						<Link to="#">Privacy Policy</Link>
					</div>
				</Item>
				<ButtonItem>
					<ButtonOptions
						width={'100%'}
						type={'default'}
						useSubmitBehavior={true}
					>
						<span className="dx-button-text">
							{loading ? (
								<LoadIndicator
									width={'24px'}
									height={'24px'}
									visible={true}
								/>
							) : (
								'Create a new account'
							)}
						</span>
					</ButtonOptions>
				</ButtonItem>
				<Item>
					<div className={'login-link'}>
						Have an account? <Link to={'/login'}>Sign In</Link>
					</div>
				</Item>
			</Form>
		</form>
	);
}

const emailEditorOptions = {
	stylingMode: 'filled',
	placeholder: 'Email',
	mode: 'email',
};
const passwordEditorOptions = {
	stylingMode: 'filled',
	placeholder: 'Contraseña',
	mode: 'password',
};
const confirmedPasswordEditorOptions = {
	stylingMode: 'filled',
	placeholder: 'Confirmar contraseña',
	mode: 'password',
};
