import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
	Item,
	Label,
	ButtonItem,
	ButtonOptions,
	RequiredRule,
	EmailRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { resetPassword } from '../../api/auth';
import './ResetPasswordForm.scss';
import { defaultShowTimeSToast } from '../../config/globalVariables';

const notificationText =
	"We've sent a link to reset your password. Check your inbox.";

export default function ResetPasswordForm() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const formData = useRef({ email: '', password: '' });

	const onSubmit = useCallback(
		async (e: any) => {
			e.preventDefault();
			const { email } = formData.current;
			setLoading(true);

			const result = await resetPassword(email);
			setLoading(false);

			if (result.isOk) {
				navigate('/login');
				notify(notificationText, 'success', defaultShowTimeSToast);
			} else {
				notify(result.message, 'error', defaultShowTimeSToast);
			}
		},
		[navigate]
	);

	// efecto para sobreescribir el background del login con el general
	useEffect(() => {
		// Cuando el componente se monta, agrega la clase
		document.getElementById('app')?.classList.add('app-login');

		// Cuando el componente se desmonta, remueve la clase
		return () => {
			document.getElementById('app')?.classList.remove('app-login');
		};
	}, []);

	return (
		<form className={'reset-password-form'} onSubmit={onSubmit}>
			<Form formData={formData.current} disabled={loading}>
				<Item
					dataField={'email'}
					editorType={'dxTextBox'}
					editorOptions={emailEditorOptions}
				>
					<RequiredRule message="Email Requerido" />
					<EmailRule message="Email inválido" />
					<Label visible={false} />
				</Item>
				<ButtonItem>
					<ButtonOptions
						elementAttr={submitButtonAttributes}
						width={'100%'}
						type={'default'}
						useSubmitBehavior={true}
					>
						<span className="dx-button-text">
							{loading ? (
								<LoadIndicator
									width={'24px'}
									height={'24px'}
									visible={true}
								/>
							) : (
								'Restablecer mi contraseña'
							)}
						</span>
					</ButtonOptions>
				</ButtonItem>
				<Item>
					<div className={'login-link'}>
						Volver al <Link to={'/login'}>Inicio de Sesión</Link>
					</div>
				</Item>
			</Form>
		</form>
	);
}

const emailEditorOptions = {
	stylingMode: 'filled',
	placeholder: 'Email',
	mode: 'email',
};
const submitButtonAttributes = { class: 'submit-button' };
